import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import first from "./1.png";
import second from "./2.png";
import third from "./gif.gif";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "gatsby";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `0.5px solid white`,
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ color: "white" }}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgb(32, 32, 38)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "white",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "white",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "#FFA100",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid #8F4FFF",
  color: "white",
  backgroundColor: "#202026",
  boxShadow: "none",
}));

export default function FAQsSection(data) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="FAQ-microREC">
      <Grid
        container
        spacing={2}
        alignItems="center"
        paddingTop={6}
        justifyContent={"space-evenly"}
      >
        <Grid item sm={6}>
          {(() => {
            switch (expanded) {
              case "panel1":
                return (
                  <img
                    src={first}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    alt=""
                  />
                );
              case "panel2":
                return (
                  <img
                    src={second}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    alt=""
                  />
                );
              case "panel3":
                return (
                  <img
                    src={third}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    alt=""
                  />
                );

              default:
                return null;
            }
          })()}
        </Grid>

        <Grid item paddingTop={6} paddingBottom={4} sm={5}>
          <h2 style={{ paddingBottom: "4", fontSize: "30px", color: "white" }}>
            The future proof-way to{" "}
            <span style={{ color: "#8E4FFF" }}>
              manage your medical imaging
            </span>
            .
          </h2>

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel3bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#8F4FFF",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1.3rem",
                }}
              >
                O1. Pathology Evolution.
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                Document each patient visit with images, conveniently store and
                access the data to track the progression of pathologies. This
                way you can monitor the evolution of patients' conditions over
                time, share images with colleagues, and even use them for
                training purposes.
              </Typography>
              <Link
                to="/32-Enhancing-Patient-Education-and-Evolution-through-MicroREC-and-MicroREC-App/"
                style={{ color: "#8F4FFF", fontWeight: "600" }}
              >
                Check how to enhance patient evolution with Connect &#8594;
              </Link>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#8F4FFF",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1.3rem",
                }}
              >
                O2. Share their expertise and improve patient outcomes.
              </h3>
            </AccordionSummary>
            <AccordionDetails id="">
              <Typography sx={{ padding: 1 }}>
                Do you need to send a video to a mentor, mentee, colleague, or
                for surgical research purposes? You can easily share it with any
                of them from any device at any time, making the process
                straightforward.
              </Typography>
              <Link
                to="/21-Digital-Transformation-in-Ophthalmology-Recording-and-Sharing-Live-Surgery/"
                style={{ color: "#8F4FFF", fontWeight: "600" }}
              >
                Learn more &#8594;
              </Link>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#8F4FFF",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1.3rem",
                }}
              >
                O3. Patient Education.{" "}
              </h3>
            </AccordionSummary>{" "}
            <AccordionDetails id="">
              <Typography sx={{ padding: 1 }}>
                You can conveniently display the diagnosis to your patient on
                your tablet or computer, instantly sharing the video through a
                link, eliminating the need for any downloads. This approach
                enhances patient satisfaction and helps alleviate pre-surgery
                anxiety.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </section>
  );
}
